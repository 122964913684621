<template>
  <div>
      <Loader v-if="loading"></Loader>
      <CRow v-else>
          <CCol lg="12">
              <AbstractTable :fields="fields" :items="psychologists" :actions="actions" title="Psicólogos"
                  :labelItemPerPage="'Psicólogos por página:'"
                  :addBtn="{ enable: true, text: 'Añadir nuevo psicólogo', event: 'addElement' }" @addElement="addPsychologist"
                  @seeInfo="handleSeeInfo"
                  @showDeleteModal="handleModal" :noResults="'No se han encontrado psicólogos'" />
              <DeleteModal :modalContent="modalContent" @delete="deletePsychologistData(psychologistId)" :showModal="deleteModal" @closeModal="closeModal" />
          </CCol>
      </CRow>
  </div>
</template>

<script>
import DeleteModal from "@/components/Modals/DeleteModal.vue";
import AbstractTable from "@/components/Tables/AbstractTable.vue";
import Loader from "@/components/Loader.vue";
import { getPsychologistsData, deletePsychologist } from "@/services/psychologists";
import { limitCharacters, generateField } from '@/utils/utils';
export default {
  name: "psychologistsTable",
  components: { AbstractTable, DeleteModal, Loader},
  data() {
      return {
          psychologists: [],
          psychologist: {},
          psychologistId: 0,
          psychologistName: '',
          psychologistSurName: '',
          loading: true,
          deleteModal: false,
          GENDER_MEN: "Hombre",
          GENDER_WOMAN: "Mujer",
          GENDER_OTHER: "Otros",
          fields: [
              generateField("name", "Nombre"),
              generateField("surname", "Apellidos"),
              generateField("username", "Nombre de usuario"),
              generateField("email", "Email"),
              generateField("actions", 'Acciones', { sorter: false, filter: false })
          ],
          actions: [
              {
                  key: 'information',
                  label: 'Información',
                  event: 'seeInfo',
                  classButton: 'btn btn btn-info btn-success btn-sm btn-pill'
              },
              {
                  key: 'delete',
                  label: 'Eliminar',
                  event: 'showDeleteModal',
                  classButton: 'btn btn-delete btn-sm btn-pill'
              },
          ],
          modalContent: {
              title: 'Eliminar psicólogo',
              textBody: '',
              buttons: [
                  {
                      textButton: 'Cerrar',
                      color: 'secondary',
                      event: 'closeModal'
                  },
                  {
                      textButton: 'Aceptar',
                      color: 'danger',
                      event: 'delete'
                  },
              ]
          }
      }
  },
  created() {
     if(!this.$store.state.isAdmin) this.$router.push(`/`);
     this.getPsychologists();
  },
  methods: {
      onEmitMethods(event, dataPsychologist) {
          switch (event) {
              case 'add': this.addPsychologist(dataPsychologist); break;
              case 'back': this.back(); break;
              default: break;
          }
      },
      /**
      * Función en la que se obtienen los psicólogos y que también
      * llama el metodo receiveData para cargarlos.
      * 
      * @returns {object[]} Los psicólogos obtenidos.
      */
      getPsychologists() {
          getPsychologistsData()
              .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() }; this.loading = false; })
              .then((result) => {
                  this.psychologists = result
                  this.psychologists.forEach((psychologist) => {
                      psychologist.name = limitCharacters(psychologist.name, 20);
                      psychologist.surname = limitCharacters(psychologist.surname, 40);
                      psychologist.username = psychologist.username;
                      psychologist.email = psychologist.email;
                  });
                  this.loading = false;
              })
      },
      /**
       * Este método se ejecuta al pulsar el botón Añadir nuevo psicólogo y redirige a la vista del formulario para poder crear uno.
       */
      addPsychologist() {
          this.$router.push(`/psychologists/create/`);
      },
      /**
      * Método para controlar lo que se muestra en el Modal de Eliminar psicólogo.
      */
      handleModal(item) {
          this.psychologistId = item.id;
          this.modalContent.textBody = `¿Está seguro de que desea eliminar el psicólogo: <strong>"${item.name} ${item.surname}</strong>"? Esta acción no se puede deshacer.`,
          this.deleteModal = true;
      },
      /**
       * Método para poder ver la información del psicólogo.
       */
      handleSeeInfo(item) {
          this.$router.push(`/psychologists/info/${item.id}`);
      },

      /**
       * Este metodo eliminará el psicólogo, mandando por la URL el id
       * 
       * @param {number} id - El id del psicólogo a eliminar
       */
      deletePsychologistData(id) {
        deletePsychologist(id).then(() => { this.getPsychologists() })
          this.deleteModal = false;
      },
      /**
       * Función para cerrar el Modal.
       */
      closeModal() {
          this.deleteModal = false;
      }
  }
};
</script>